import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Countdown from 'react-countdown';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { validate } from 'wallet-address-validator';

import './index.css';
import '../../static/css/animate.css';
import { web3 } from "../../store/web3";
import { getNFTdata } from "../../store/actions/Auth";
import { Token, TokenAddress, TokenABI } from '../../store/contract/index';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

class TEX extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    render() {

        const owl_option = {
            margin: 30,
            nav: true,
            items: 4,
            loop: true,
            autoplay: false,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 4,
                }
            },
        };

        return (
            <div className="tex-page">
                <Navbar />
                <section className="banner-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/banner-bg.png")})` }} id="home">
                    <div className="auto-container">
                        <div className="row">
                            <div className="banner-text col-lg-6 col-md-12">
                                <div className="banner-inner">
                                    <h1>The safest way to <br /> own Silver</h1>
                                    <a className="btn-style-one" href="#">BUY NOW</a>
                                </div>
                            </div>
                            <div className="allocation-block col-lg-6 col-md-12">
                                <div className="allocation-box">
                                    <div className="allocation-inner">
                                        <h4>Silver Allocation Lookup</h4>
                                        <p>Every TEX token is backed by a value of 1 gram of silver. Use this lookup to get information about your silver holdings.</p>
                                        <form className="allocation-form">
                                            <div className="group-form">
                                                <input type="text" name="ETnumber" placeholder="Enter address" />
                                                <button className="btn-style-one">Lookup</button>
                                            </div>
                                        </form>
                                    </div>
                                    <p>This Lookup tool is for TEX held in on-chain Ethereum wallets (not custodial exchanges/wallets). For TEX held on KEEP, login to view your allocation report.</p>
                                    {/* <img src={require('../../static/images/landing/banner-img.png')} alt='' /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="about-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/about-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="about-text col-lg-6 col-md-12">
                                <div className="about-inner">
                                    <h2>A Digital Token backed by Physical Silver</h2>
                                    <p>TEX is a stable token that will be backed by the value of 1 gram of silver and the number of grams of silver is determined by the number of unique data fields available for each user (the Human Identity).</p>
                                </div>
                            </div>
                            <div className="video-block col-lg-6 col-md-12">
                                <div className="video-box">
                                    <img src={require('../../static/images/landing/about-img.png')} alt='' />
                                    <div className="overlaybox">
                                        {/* <a href="#" className="video-icon"><img src={require('../../static/images/landing/play.png')} alt='' /></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="services-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/services-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">

                            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                                <div className="service-box">
                                    <div className="icon-box">
                                        <img src={require('../../static/images/landing/service-1.png')} alt='' />
                                    </div>
                                    <div className="text-box">
                                        <h3>High Accessibility </h3>
                                        <p>Built as an ERC-20 token on the Ethereum blockchain, TEX can easily be moved or traded anywhere in the world, 24/7. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                                <div className="service-box">
                                    <div className="icon-box">
                                        <img src={require('../../static/images/landing/service-2.png')} alt='' />
                                    </div>
                                    <div className="text-box">
                                        <h3>Trusted </h3>
                                        <p>TEX is audited by Certik and is trusted by people all around the world. It’s becoming a standard way of holding digital silver.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                                <div className="service-box">
                                    <div className="icon-box">
                                        <img src={require('../../static/images/landing/service-3.png')} alt='' />
                                    </div>
                                    <div className="text-box">
                                        <h3>Redeemable</h3>
                                        <p>TEX token will be redeemable and the owner can exchange physical silver for TEX.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                                <div className="service-box">
                                    <div className="icon-box">
                                        <img src={require('../../static/images/landing/service-4.png')} alt='' />
                                    </div>
                                    <div className="text-box">
                                        <h3>Flexible</h3>
                                        <p>Easily convert or redeem TEX for fiat. Trade TEX against other digital assets on popular crypto exchanges. Use TEX wherever ERC-20 tokens are supported.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="service-block col-lg-4 col-md-6 col-sm-12">
                                <div className="service-box">
                                    <div className="icon-box">
                                        <img src={require('../../static/images/landing/service-5.png')} alt='' />
                                    </div>
                                    <div className="text-box">
                                        <h3>Low fees</h3>
                                        <p>Extremely competitive fee structure for creations and redemptions of TEX tokens in the TEX wallet, low on-chain Ethereum transaction fees and minimal storage fees. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 btn-area">
                                <a href="https://tex-whitepaper.s3.amazonaws.com/TEX-Whitepaper.pdf" target="_blank" className="btn-style-one">
                                    <i className="icon"><img src={require('../../static/images/landing/white-paper.png')} alt='' /></i>
                                    Learn more about TEX in our whitepaper
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="buytex-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/buy-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="buy-inner">
                                    <h2>Buy TEX Silver Now!</h2>
                                    <a className="btn-style-two" href="#">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="price-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>Why TEX Silver?</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="price-table-box">
                                    <table className="price-table">
                                        <tbody>
                                            <tr>
                                                <td className="price-blank"></td>
                                                <td className="price-table-popular"></td>
                                                <td className="price-blank"></td>
                                                <td className="price-blank"></td>
                                            </tr>
                                            <tr className="price-table-head">
                                                <td>Custody Fees</td>
                                                <td>NO FEE</td>
                                                <td>19-40 bps per annum</td>
                                                <td>N/A</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Purchase</td>
                                                <td>0.01g oz ~ $20</td>
                                                <td>1 share (currently $200)</td>
                                                <td>1 contract (100t oz = $200k)</td>
                                            </tr>
                                            <tr>
                                                <td>Time to Settle</td>
                                                <td>INSTANT*</td>
                                                <td>T+2 days</td>
                                                <td>Expiration date</td>
                                            </tr>
                                            <tr>
                                                <td>Allocated</td>
                                                <td><i className="fa fa-check"></i></td>
                                                <td>Variable</td>
                                                <td><i className="fa fa-times"></i></td>
                                            </tr>
                                            <tr>
                                                <td>Instantly Redeemable <br /> for Physical</td>
                                                <td><i className="fa fa-check"></i></td>
                                                <td><i className="fa fa-times"></i></td>
                                                <td><i className="fa fa-times"></i></td>
                                            </tr>
                                            <tr className="last-area">
                                                <td>Regulated</td>
                                                <td>NYDFS</td>
                                                <td>SEC and equvalents</td>
                                                <td>CFTC</td>
                                            </tr>
                                            <tr className="bottom-area">
                                                <td className="price-blank"></td>
                                                <td className="price-table-popular"></td>
                                                <td className="price-blank"></td>
                                                <td className="price-blank"></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sponser-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/sponser-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>TEX Silver Ecosystem</h2>
                                    <p>TEX silver is now handled by over 20 vaults, exchanges, wallets, lending platforms and more including:</p>
                                </div>
                            </div>
                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/1.svg')} alt='' />
                                </div>
                            </div>
                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/2.png')} alt='' />
                                </div>
                            </div>
                            {/* <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/3.png')} alt='' />
                                </div>
                            </div>
                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/4.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/5.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/6.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/7.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/8.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/9.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/10.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/11.png')} alt='' />
                                </div>
                            </div>

                            <div className="sponser-block col-lg-3 col-md-6 col-sm-12">
                                <div className="sponser-inner">
                                    <img src={require('../../static/images/landing/12.png')} alt='' />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                {/* FAQ's Section */}
                <section id="faqs" className="faqs-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/faq-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2>FAQs</h2>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    What is TEX?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                TEX is an asset-backed token where one token represents one gram silver, stored in professional vault facilities. Anyone who owns TEX has ownership rights to that Silver under the custody of The Ellipsis Exchange.  Since TEX represents physical gold, its value is tied directly to the real-time market value of Silver.</div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What are the benefits of having Physical assets on blockchain?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div class="card-body">
                                                Physical Silver on the blockchain has the advantages of representing legal ownership of physical allocated silver, but does not have the drawbacks of limited transportability or high storage costs usually associated with it. Instead, it has the divisibility, fungibility and tradability of any digital asset like bitcoin. In other words, the best of physical and digital assets at the same time!</div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Can I get a fractional amount of a TEX token?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div class="card-body">
                                                Yes. It is possible to own tiny fractions of a token, which are divisible out to 18 decimal points. If you purchase TEX directly from The Ellipsis Exchange, the minimum purchase is 0.01 TEX.</div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingFour">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    What are on-chain transaction fees? Are they the same thing as 'gas'?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                            <div class="card-body">
                                                Sending a TEX token from one ERC-20 address to another, such as when moving funds from a wallet to an exchange, is an on-chain transaction; possession of the token is transferred and recorded on the Ethereum blockchain.
                                        <br /><b>There are two kinds of fees that occur when you send TEX on the blockchain: </b>
                                                <ul>
                                                    <li>Standard ‘gas’ fee: Sending digital assets on Ethereum, requires computing power, or ‘gas.’ Just like any other Ethereum token, TEX requires standard gas fees paid in ETH to compute the transaction. </li>
                                                    <li>TEX on-chain transaction fee: TEX charges an additional fee. That fee is set to 0.02% of the amount of TEX sent on the blockchain.</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = {
    getNFTdata
};

const mapStateToProps = ({ Auth }) => {
    let { nftData } = Auth;
    return { nftData }
};

export default connect(mapStateToProps, mapDispatchToProps)(TEX);