import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { web3 } from '../../store/web3';
import { networkId } from '../../store/config';
import { login } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: localStorage.getItem('publicAddress'),
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', `Please Change Your Network to Rinkeby Test Network`);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.login(address);
        });
    };

    render() {
        let { address } = this.state;
        console.log("********address:::", address);
        console.log("********publicAddress:::", localStorage.getItem('publicAddress'));
        return (
            <div className="tex-nav">
                <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                    <div className='container-fluid'>
                        <div className="inner-container">
                            <Link className='navbar-brand' href='#'><img src={require('../../static/images/landing/logo.png')} alt='' /></Link>
                            <button className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i class='fa fa-bars' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                <div className="close-btn-area">
                                    <button className='navbar-toggler close-button' type='button' data-toggle='collapse'
                                        data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                        aria-label='Toggle navigation' aria-haspopup="true">
                                        <i class='' aria-hidden='true'>&times;</i>
                                    </button>
                                </div>
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link active' smooth to='/#home'>Products</HashLink>
                                    </li>
                                    <li className='nav-item dropdown'>
                                        <HashLink className='nav-link dropdown-toggle' smooth to='/#'><i className="icon"><img src={require('../../static/images/landing/language.png')} alt='' /></i>Eng  <i className="icon"><img src={require('../../static/images/landing/arrow down.png')} alt='' /></i></HashLink>
                                        <ul className="submenu dropdown-menu">
                                            <li className='dropdown-item'>
                                                <HashLink className='nav-link' smooth to='/#'>Arabic</HashLink>
                                            </li>
                                            <li className='dropdown-item'>
                                                <HashLink className='nav-link' smooth to='/#'>Chinese</HashLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <div className="nav-outer">
                                        <button className="login-btn">Login</button>
                                        <button className="registr-btn">Register</button>
                                    </div>
                                </ul>
                                
                                
                            </div>
                        </div>
                    </div>

                </nav>
            </div>
        );
    }
}

const mapDispatchToProps = {
    login
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);