import { PURGE } from "redux-persist";

let initialState = {
  publicAddress: localStorage.getItem('publicAddress'),
  nftData: [],
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'LOGIN':
      console.log("***********pyaload:::", payload);
      localStorage.setItem('publicAddress', payload);
      return {
        ...state,
        publicAddress: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem('publicAddress');
      return {
        ...state,
        publicAddress: '',
      };

    case 'SET_NFT_DATA':
      return {
        ...state,
        nftData: payload,
      };

    default:
      return state;
  }
};

export default Auth;