/* -- set app title --*/
const AppTitle = 'TEX CROWDPOINT';

/* -- set app mode -- */
const AppMode = [''];
// const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://dicoserver.merklecoin.com';
const production = 'https://pserver.merklecoin.com';
const development = 'https://dicoserver.merklecoin.com';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';
// let env = 'production', networkId = '', message = '', explorer = '';
switch (AppMode[0]) {
  case 'development':
    networkId = 4;
    message = 'Please switch your network to Rinkeby testnet';
    SocketUrl = development;
    explorer = 'https://rinkeby.etherscan.io'
    break;
  case 'production':
    networkId = 56;
    SocketUrl = production;
    message = 'Please switch your network to BSC Mainnet';
    explorer = 'https://bscscan.com'
    break;
  case 'testing':
    networkId = 4;
    SocketUrl = testing;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    break;
  default:
    networkId = 4;
    SocketUrl = 'http://192.168.18.17:4000';
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
}

let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env };