import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

// import { web3 } from '../web3';
import { setNFTdata } from '../actions/Auth';

function* getNFTdata() {
  const { error, response } = yield call(getCall, '/getCsv'); if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) yield put(setNFTdata(response['data']['body']));
};

// function* action({ payload }) {
//   const { error, response } = yield call(postCall, { path: 'pathName', payload });
//   if (error) EventBus.publish('error', error['response']['data']['message'])
//   else if (response) EventBus.publish('success', response['data']['message']);
// };


function* actionWatcher() {
  yield takeEvery('GET_NFT_DATA', getNFTdata);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
