export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});


export const getNFTdata = () => ({
  type: 'GET_NFT_DATA',
});

export const setNFTdata = (data) => ({
  type: 'SET_NFT_DATA',
  payload: data
});
